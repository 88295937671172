.buttonContainer {
  display: grid;
  gap: 1.75rem;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
}

.buttons {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-radius: 0.25rem;
  cursor: pointer;
  height: 3.5rem;
  --tw-bg-opacity: 0.9;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  font-weight: 100;
  color: rgb(68, 68, 68);
  border-color: rgb(255, 255, 255);
  box-shadow: rgb(236, 236, 236) 0px 0px 0px 3px;
  font-size: 1.1rem;
  line-height: 2rem;
  letter-spacing: 0.025em;
  outline: none !important;
}

.amountSelected {
  color: #28a745 !important;
  font-weight: 400;
  box-shadow: #28a745 0px 0px 0px 3px;
}

.otherAmountText {
  height: 100%;
  width: 100%;
  border: none;
  padding: 5px;
  color: #28a745;
  text-align: center;
}
.otherAmountText:focus {
  border: none !important;
  outline: none !important;
}
.tl {
  position: absolute;
  top: 2px;
  left: 10px;
  z-index: 1000;
}
.tl:before {
  content: '\20BA';
  content: 'TL';
  font-weight: 100;
}
