table.table-bordered {
    border: 1px solid rgb(243, 243, 243);
    margin-top: 20px;
}
table.table-bordered > thead > tr > th {
    border: 1px solid rgb(243, 243, 243);
}
table.table-bordered > tbody > tr > td {
    border: 1px solid rgb(243, 243, 243);
}
h5 {
    color: #6c757d !important;
    margin: 30px 0 0 0;
}
