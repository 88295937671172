.btn-share {
    border: 1px solid #333 !important;
    border-radius: 3px !important;
    background-color: white;
    color: #111;
    width: 100% !important;
    text-align: center;
    display: block;
}

.btn-share:hover {
    background: rgb(216, 222, 243);
}
